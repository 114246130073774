/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import './App.scss';

const Analytics = React.lazy(() => import('./components/analytics/Analytics.component'));
const Surveys = React.lazy(() => import('./components/surveys/Surveys'));
const CreateFeedback = React.lazy(() => import('./components/surveys/create/CreateSurvey'));
const Login = React.lazy(() => import('./components/auth/Login'));
const Auth = React.lazy(() => import('./components/auth/Auth'));
const Register = React.lazy(() => import('./components/auth/Signup'));
const Onboarding = React.lazy(() => import('./components/auth/onboarding/Onboarding'));
const Documentation = React.lazy(() => import('./components/documentation/Documentation'));
const PaymentSucess = React.lazy(() => import('./components/common/PaymentSuccess'));
const PaymentError = React.lazy(() => import('./components/common/PaymentError'));
const RewardsComponent = React.lazy(() => import('./components/rewards/Rewards.component'));
const Roadmaps = React.lazy(() => import('./components/roadmaps/Roadmap.component'));
const PublicRoadmap = React.lazy(() => import('./components/roadmaps/public/PublicRoadmap'));

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse">
			<Spinner animation="border" variant="success" />
		</div>
	</div>
);

const Redirect = ({ to }) => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate(to);
	});
	return null;
}

const App = () => (
	<div className='app-container'>
		<React.Suspense fallback={loading}>
			<Routes>
				<Route path="/" element={<PublicRoadmap />} />
				<Route path="/:feature" element={<PublicRoadmap />} />
				<Route path="/:feature/:slug" element={<PublicRoadmap />} />

				<Route path="/payment-sucess" element={<PaymentSucess />} />
				<Route path="/payment-error" element={<PaymentError />} />

				<Route path="/admin" element={<Redirect to="/admin/roadmaps" />} />
				<Route path="/admin/rewards" element={<RewardsComponent />} />
				<Route path="/admin/roadmaps" element={<Roadmaps />} />

				{/* old path being redirected to new path */}
				<Route path="/company/:companyId/:feature" element={<PublicRoadmap oldurl />} />
				<Route path="/company/:companyId/:feature/:slug" element={<PublicRoadmap oldurl />} />

				<Route path="/signup/appsumo" element={<Onboarding />} />
				<Route path="/signup/appsumo/:code" element={<Onboarding />} />

				<Route path="/login" element={<Login />} />
				<Route path="/signup" element={<Onboarding />} />
				<Route path="/workspace/register" element={<Onboarding />} />
				<Route path="/auth" element={<Auth />} />

				<Route path="/admin/analytics" element={<Analytics />} />
				<Route path="/admin/analytics/survey/:surveyId" element={<Analytics />} />
				<Route path="/admin/documentation" element={<Documentation />} />

				<Route path="/admin/surveys" element={<Surveys />} />
				<Route path="/admin/survey/create" element={<CreateFeedback />} />
				<Route path="/admin/survey/draft/:draftId" element={<CreateFeedback />} />
				<Route path="/admin/survey/edit/:surveyId" element={<CreateFeedback />} />
			</Routes>
		</React.Suspense>
	</div>
)

export default App;
